import type {
  Recommendation as BaseRecommendation,
  RecommendationExclusive,
  Season,
} from '../../@schema';

import { create } from 'zustand';

export type VCMStoreState = {
  isAbleToReload: boolean;
  setIsAbleToReload: (isAbleToReload: boolean) => void;
  tabSelected: string;
  setTabSelected: (tabSelected: string) => void;
  tabsSelectedCount: number;
  setTabsSelectedCount: (count: number) => void;
  setCacheRecommendations: (data: BaseRecommendation | null) => void;
  cachedRecommendations: BaseRecommendation | null;
  setCacheExclusiveContent: (data: RecommendationExclusive | null) => void;
  cachedExclusiveContent: RecommendationExclusive | null;
  cacheSeasons: Season | null;
  setCacheSeasons: (data: Season | null) => void;
};

export const useVCMStore = create<VCMStoreState>((set) => ({
  isAbleToReload: false,
  isAbleToTrack: false,
  tabSelected: '',
  setTabSelected: (tabSelected) => set((state) => ({ ...state, tabSelected })),
  setIsAbleToReload: (isAbleToReload) =>
    set((state) => ({
      ...state,
      isAbleToReload,
    })),
  cachedRecommendations: null,
  setCacheRecommendations: (data: BaseRecommendation | null) =>
    set((state) => ({
      ...state,
      cachedRecommendations: data,
    })),
  cachedExclusiveContent: null,
  setCacheExclusiveContent: (data: RecommendationExclusive | null) =>
    set((state) => ({
      ...state,
      cachedExclusiveContent: data,
    })),

  setCacheSeasons: (data: Season | null) =>
    set((state) => ({ ...state, cacheSeasons: data })),
  cacheSeasons: null,
  tabsSelectedCount: 0,
  setTabsSelectedCount: (count) =>
    set((state) => ({ ...state, tabsSelectedCount: count })),
}));
