import type { NbcuMessageProps } from './NbcuMessage.type';
import type { VcmCtaOptionsListType } from '../../../../../@domain/hooks/useVCMTrack/useVCMTrack.type';

import { Message } from '@andes/message';
import { Typography } from '@andes/typography';
import { useI18n } from 'nordic/i18n';

import { useVcmTrack } from '../../../../../@domain/hooks/useVCMTrack';
import { useStaticData } from '../../../../../context';
import { useFullVCMNavigation } from '../../../../../@domain/hooks/useFullVCMNavigation';
import { useRestriction } from '../../../../../context/restrictions-store/restriction.store';
import { VCM_CTA_OPTIONS_LIST_TYPED } from '../../../../../@domain/hooks/useVCMTrack/constants';

export const NbcuMessage = ({
  isUserLoggedIn,
  data,
  currency,
}: NbcuMessageProps) => {
  const { i18n } = useI18n();
  const { runEventTrack } = useVcmTrack(undefined, data);
  const { navigateToExternalView } = useFullVCMNavigation();
  const { isOpenModalLock, setIsOpenModalLock } = useRestriction();

  const handleClick = (externalPath: string): void => {
    runEventTrack({
      selected_option:
        VCM_CTA_OPTIONS_LIST_TYPED.KNOWMORE as unknown as VcmCtaOptionsListType,
    });

    if (isUserLoggedIn) {
      navigateToExternalView(externalPath);
    } else {
      setIsOpenModalLock(isOpenModalLock);
    }
  };

  const { siteId } = useStaticData();

  const path = siteId?.includes('MLB') ? '/ajuda/30463' : '/ayuda/30463';

  const message = isUserLoggedIn
    ? i18n.gettext(
        'Para disfrutar de todo el contenido exclusivo, tienes que tener compras en Mercado Libre por {0}',
        currency,
      )
    : i18n.gettext(
        'Inicia sesión con tu cuenta de Mercado Libre y disfruta de todo el contenido exclusivo.',
      );

  return (
    <Message
      className="custom-message-nbcu-restriction"
      hierarchy="quiet"
      color="orange"
      title={
        <>
          <Typography size="s" weight="regular">
            {message}
          </Typography>
          <Typography
            className="custom-typo-log-in"
            onClick={() => handleClick(path)}
            color="link"
            size="s"
          >
            {i18n.gettext('Conocer más')}
          </Typography>
        </>
      }
    />
  );
};
