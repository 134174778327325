import type { RestrictionConfirmation } from '../../../context/restrictions-store/restriction.store';
import type { RestrictionProps } from './Restriction.type';
import type { ComponentType, FC } from 'react';

import { useState } from 'react';

import { Modal as AndesModal } from '@andes/modal';
import { Button } from '@andes/button';
import { Snackbar } from '@andes/snackbar';
import { useI18n } from 'nordic/i18n';

import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import paths from '../../../constants/paths-tracks.json';
import { ContentLockModal } from '../../webview/ContentLockModal';
import { LoginMessage } from './components/LoginMessage/LoginMessage';
import { NbcuMessage } from './components/NbcuMessage/NbcuMessage';
import { MaxQuotaMessage } from './components/MaxQuotaMessage/MaxQuotaMessage';
import { RatingAgeMessage } from './components/RagtingAgeMessage/RatingAgeMessage';
import { useStaticData } from '../../../context';

const { CONTENT_ACCESS_MODAL } = paths;

type DefaultPrimaryProps = {
  fullWidth: boolean;
  titleCtaContinue: string;
  onClick: () => void;
  disabled: boolean;
};

interface WithLoadingProps {
  loading: boolean;
}

const DefaultPrimaryButton: FC<DefaultPrimaryProps> = ({
  fullWidth,
  onClick,
  titleCtaContinue,
  ...rest
}) => (
  <Button {...rest} fullWidth={fullWidth} onClick={onClick}>
    {titleCtaContinue}
  </Button>
);

const withLoading =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P & WithLoadingProps) => {
    const { loading, ...rest } = props;

    return <WrappedComponent {...(rest as P)} loading={loading} />;
  };

const DefaultPrimaryButtonWithLoading = withLoading(DefaultPrimaryButton);

export const Restriction: FC<RestrictionProps> = ({
  children,
  titleCtaContinue,
  titleCtaCancel,
  title,
  type,
  fullWidthBtn = false,
  tracks,
  onContinue,
  className,
  currency,
  data,
  userContext,
  buttonType = 'default',
  context,
}) => {
  const { request, snackbarVisible, setSnackbarVisible, permission } =
    useRestriction();
  const [loading, setLoading] = useState(false);

  const { siteId } = useStaticData();

  const { i18n } = useI18n();

  const {
    hasNbcuRestriction,
    nbcuUserLoggedIn,
    isModalOpen,
    maxQuotaRestriction,
    hasRatingAge,
    hasGuestUserRestriction,
    nbcuUserGuest,
  } = permission;

  const handleRestrictionConfirmation = async (
    confirmation: RestrictionConfirmation,
  ) => {
    if (buttonType === 'loading') {
      setLoading(true);
    }

    await request({
      content_access_confirmation: confirmation,
    })
      .then(() => {
        onContinue?.();
      })
      .finally(() => {
        setLoading(false);
      });

    const eventData = {
      context,
      content_access_confirmation: confirmation,
      vertical: 'mplay',
      ...(tracks?.contentId ? { content_id: tracks.contentId } : {}),
      ...(tracks?.guestUserId ? { guest_user_id: tracks.guestUserId } : {}),
    };

    window?.melidata?.('cleanAndSend', 'event', {
      path: CONTENT_ACCESS_MODAL,
      event_data: eventData,
    });
  };

  const handleCancel = async () => handleRestrictionConfirmation('rejected');
  const handleContinue = async () => handleRestrictionConfirmation('accepted');

  const primaryButtonProps: DefaultPrimaryProps = {
    fullWidth: fullWidthBtn,
    titleCtaContinue,
    onClick: handleContinue,
    disabled: loading,
  };

  const actions = {
    fixed: false,
    primary:
      buttonType === 'loading' ? (
        <DefaultPrimaryButtonWithLoading
          {...primaryButtonProps}
          loading={loading}
        />
      ) : (
        <DefaultPrimaryButton {...primaryButtonProps} />
      ),
    secondary: (
      <Button
        fullWidth={fullWidthBtn}
        hierarchy="quiet"
        onClick={handleCancel}
        disabled={loading}
      >
        {titleCtaCancel}
      </Button>
    ),
  };

  const renderRestrictions = () => {
    if (isModalOpen) {
      return (
        <AndesModal
          type={type}
          open={isModalOpen}
          title={title}
          actions={actions}
          closable={false}
          className={className}
        >
          {children}
        </AndesModal>
      );
    }

    if (hasNbcuRestriction) {
      return (
        <>
          <NbcuMessage
            isUserLoggedIn={nbcuUserLoggedIn && !nbcuUserGuest}
            data={data}
            currency={currency || ''}
            userContext={userContext}
          />
          <ContentLockModal
            data={data}
            currency={currency || ''}
            userContext={userContext}
          />
        </>
      );
    }

    if (siteId === 'MLB' && (hasRatingAge || hasGuestUserRestriction)) {
      return <LoginMessage title={data?.name} />;
    }

    if (hasGuestUserRestriction || maxQuotaRestriction) {
      return <MaxQuotaMessage />;
    }

    // TODO: handle geolocation restriction
    // if (restrictions.includes(RESTRICTIONS_VALUES.GEOLOCATION)) {
    //   return ...
    // }

    if (hasRatingAge) {
      return <RatingAgeMessage />;
    }

    return null;
  };

  return (
    <>
      {renderRestrictions()}
      <Snackbar
        message={i18n.gettext(
          'No pudimos guardar tu configuración, inténtalo nuevamente.',
        )}
        show={snackbarVisible}
        color="red"
        delay={5000}
        onClose={() => setSnackbarVisible(false)}
      />
    </>
  );
};
