import type { FC } from 'react';
import type { ContextValue } from '../../pages/hub/context';
import type { TabSelectorProps } from '../../components/TabSelector';
import type { TagsProps } from '../../components/TagSelector';
import type { HubFiltersProps } from './HubFilters.types';

import { useCallback, useRef, useContext, useEffect, useState } from 'react';

import {
  getRealFilterTrack,
  ProviderTrack,
  ProviderTrackEventName,
  sendPlatformTracks,
} from '../../utils/track';
import { HubContext } from '../../pages/hub/context';
import { TabSelector } from '../../components/TabSelector';
import pages from '../../constants/pages.json';
import { TagSelector } from './components/TagSelector';
import { validateInitialFilter } from './utils/validate-filter';
import { useStickyHeader } from '../../components/StickyHeaderWebview/context/StickyHeaderProvider';
import { useMainTracksStore } from '../../context/tracks-webview/tracks-webview';
import { useViewport } from '../../context/viewport-visibility/viewport-visibility';
import { MobileWebKit } from '../../@util/webview';

const { HUB_PAGE } = pages;

export const HubFilters: FC<HubFiltersProps> = ({
  selected = 0,
  tracks,
  behavior,
  tabs,
  ...props
}) => {
  const { handleSubfilters, handleFirstTab } = useStickyHeader();
  const { mainTracks } = useMainTracksStore();

  const { filter, updateFilter, setInitialFilter } =
    useContext<ContextValue>(HubContext);
  const { setFilterChange } = useViewport();
  const [filterSelected, setFilterSelected] = useState(selected);
  const cache = useRef(new Map<string | undefined, number>()).current;

  const replaceStateUrl = useCallback(() => {
    const baseWebviewUrl = '/webview';
    const hubPageUrl = HUB_PAGE;

    let lastUrl: string | null = window?.location.pathname ?? null;

    return (filter?: string): void => {
      const filterValue = filter || '';
      const newUrl = !window.MobileWebKit
        ? `${hubPageUrl}${filterValue}`
        : `${baseWebviewUrl}${filterValue ? `?filters=${filterValue}` : ''}`;

      if (lastUrl !== newUrl) {
        lastUrl = newUrl;
        window.history.replaceState({}, '', newUrl);
      }
    };
  }, [])();

  const getTabIndexByFilter = useCallback(
    (filterSearch?: string): number => {
      if (cache.has(filterSearch)) {
        return cache.get(filterSearch)!;
      }

      const tabIndex = tabs.findIndex(
        (filter) => filter.filter === filterSearch,
      );
      const result = tabIndex !== -1 ? tabIndex : 0;

      cache.set(filterSearch, result);

      return result;
    },
    [tabs, cache],
  );

  const getFilterByIndexTabs = (indexFilter: number) =>
    tabs[parseInt(indexFilter.toString(), 10)];

  const sendTracks = useCallback((value: string, index: number) => {
    const dataTracks = {
      path:
        tracks?.[ProviderTrackEventName.CHANGE]?.[ProviderTrack.MELIDATA]
          ?.path || '',
      typeEvent:
        tracks?.[ProviderTrackEventName.CHANGE]?.[ProviderTrack.MELIDATA]
          ?.type || 'view',
      eventData: {
        selected_value: value,
        selected_index: index,
        selection_gesture: 'click',
        filter: getRealFilterTrack(filter),
      },
      tracks: {
        ...tracks?.[ProviderTrackEventName.CHANGE]?.[ProviderTrack.MELIDATA]
          ?.event_data,
        ...mainTracks?.[ProviderTrackEventName.DISPLAY]?.[
          ProviderTrack.MELIDATA
        ]?.event_data,
      },
      trackEventName: ProviderTrackEventName.CHANGE,
    };

    sendPlatformTracks(dataTracks);
  }, []);

  const handleTabClick: TabSelectorProps['onClick'] = (tab, index) => {
    if (tab?.action) {
      MobileWebKit.push({ url: tab.action });

      return;
    }

    handleFirstTab(index === 0);
    handleSubfilters(false);
    replaceStateUrl(tab.filter);
    setFilterSelected(getTabIndexByFilter(tab.filter));
    updateFilter({ filter: tab.filter || '' });
    window.scrollTo(0, 0);
    sendTracks(tab.value, index);
    setFilterChange(tab.value);

    if (tab.secondLevel) {
      handleSubfilters(true);
    }
  };

  useEffect(() => {
    if (tabs[selected].secondLevel && window.MobileWebKit) {
      handleSubfilters(true);
    }
  }, [selected]);

  useEffect(() => {
    const filterValue = getFilterByIndexTabs(selected);

    if (filterSelected !== selected) {
      setFilterSelected(selected);
    }

    const initialFilter = validateInitialFilter({
      filter: filterValue.filter || '',
      subfilters: filterValue.secondLevel?.props,
    });

    setInitialFilter({ filter: initialFilter! });
    replaceStateUrl(initialFilter);
  }, []);

  useEffect(() => {
    if (mainTracks) {
      const dataTracks = {
        path:
          tracks?.[ProviderTrackEventName.DISPLAY]?.[ProviderTrack.MELIDATA]
            ?.path || '',
        typeEvent:
          tracks?.[ProviderTrackEventName.DISPLAY]?.[ProviderTrack.MELIDATA]
            ?.type || 'view',
        trackEventName: ProviderTrackEventName.DISPLAY,
        tracks: {
          ...tracks,
          ...mainTracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.event_data,
        },
      };

      sendPlatformTracks(dataTracks);
    }
  }, [mainTracks]);

  const handleChangeSubfilter = useCallback(
    (tag: TagsProps, index: number) => {
      if (filter !== tag.filter) {
        updateFilter({ filter: tag.filter! });
        replaceStateUrl(tag.filter);
        sendTracks(tag.value, index);
      }
    },
    [updateFilter, replaceStateUrl, sendTracks],
  );

  return (
    <>
      <TabSelector
        className="filters-container"
        selected={filterSelected}
        behavior={behavior}
        onClick={handleTabClick}
        tabs={tabs}
        {...props}
      />
      <TagSelector
        tagSelector={tabs[filterSelected].secondLevel}
        handleChangeSubfilter={handleChangeSubfilter}
      />
    </>
  );
};
