import type {
  NavigationMethods,
  NavigationOriginParams,
  VCMDeeplinkBuilder,
} from './types';
import type { Action } from '../../../components/MediaCardVertical/MediaCardVertical.types';
import type { ActionDialogBoxProps } from '../../../components/DialogBox/DialogBox.type';

import { useCallback } from 'react';

import { MobileWebKit } from '../../../@util/webview';
import { QueryParams } from '../../../utils/query-params';
import { handleSkinCast } from '../../../utils/webkit/custom-native-events';
import { useStaticData } from '../../../context/static-data-context';

const WEBVIEW_PREFIX = 'meli://webview/';
const VCM_GROUP = 'VCM';
const DEEPLINK_PARAMS =
  '&hides_bottom_bar=true&bar_visibility=gone&allows_media_playback=true&bar_title=&use_web_title=false&bar_left_button_style=none';

export const buildVCMDeeplink: VCMDeeplinkBuilder = ({
  contentId,
  currentHost,
  closeAllAction,
  scope,
  originParams,
  startTime,
}) => {
  const params = new URLSearchParams();

  if (startTime) {
    params.append('start_time', startTime.toString());
  }

  if (scope) {
    params.append('version', scope);
  }

  if (closeAllAction === 'push') {
    params.append('close_all_action', closeAllAction);
  }

  if (originParams) {
    Object.entries(originParams).forEach(([key, value]) => {
      params.append(key, value);
    });
  }

  const VCMPath = encodeURIComponent(
    `${currentHost}/webview/vcm/${contentId}?${params.toString()}`,
  );

  return `${WEBVIEW_PREFIX}?webkit-engine=2${DEEPLINK_PARAMS}&url=${VCMPath}`;
};

export const buildCFSDeeplink = ({
  contentId,
  ...originParams
}: NavigationOriginParams) => {
  const params = new URLSearchParams();

  if (originParams) {
    Object.entries(originParams).forEach(([key, value]) => {
      params.append(key, value);
    });
  }

  return `meli://mplay-redirect/cfs?url=/frontend/content/${contentId}/cfs&${params.toString()}`;
};

export const navigateToFullVCM = (deeplink: string, replace = false) => {
  MobileWebKit.push({
    url: deeplink,
    args: {
      screens_group: VCM_GROUP,
      replace,
      transition: 'present',
    },
    onSuccess: async () =>
      MobileWebKit.setGroupStore(({ screen = 0 }: { screen: number }) => ({
        screen: screen + 1,
      })),
  });
};

export const reloadVCM = () => {
  MobileWebKit.reload();
};

export const useFullVCMNavigation = () => {
  const { isWebKit2, currentHost, scope, redirectType, closeAllAction } =
    useStaticData();

  const navigateToVCM = useCallback(
    (
      action: Action | ActionDialogBoxProps,
      navigationMethod?: NavigationMethods,
    ) => {
      if (!isWebKit2) {
        navigationMethod?.webKit1?.(action as unknown as Action);

        return;
      }

      let origins;

      if (action.deeplink) {
        origins = QueryParams.filterParamsWithCustomPrefix(action.deeplink);
      } else {
        origins = (action as Action).origin;
      }

      const VCMDeeplink = buildVCMDeeplink({
        contentId: action.contentId || '',
        currentHost: currentHost || '',
        scope,
        originParams: origins as Record<string, string>,
        startTime: action.startTime,
      });

      navigateToFullVCM(VCMDeeplink);
    },
    [currentHost, isWebKit2, scope],
  );

  const navigateToRecommendation = useCallback(
    ({ contentId, ...originParams }: NavigationOriginParams) => {
      const shouldUsePush =
        redirectType === 'direct' || closeAllAction === 'push';
      const closeAllParam = shouldUsePush ? 'push' : '';

      const VCMDeeplink = buildVCMDeeplink({
        currentHost,
        contentId,
        closeAllAction: closeAllParam,
        scope,
        originParams,
      });

      navigateToFullVCM(VCMDeeplink);
    },
    [closeAllAction, currentHost, redirectType, scope],
  );

  const navigateToCFS = useCallback(
    ({
      contentId,
      deeplink: deeplinkVcm,
      ...originParams
    }: NavigationOriginParams) => {
      let origins;

      if (deeplinkVcm) {
        origins = QueryParams.filterParamsWithCustomPrefix(deeplinkVcm);
      } else {
        origins = originParams;
      }

      const deeplink = buildCFSDeeplink({ contentId, ...origins });

      handleSkinCast({ deeplink });
    },
    [],
  );

  const handleBack = useCallback(() => {
    MobileWebKit.pop();
  }, []);

  const handleCloseAll = useCallback(() => {
    MobileWebKit.closeScreens(VCM_GROUP);
  }, []);

  const navigateToLogin = useCallback(
    (contentId: string) => {
      const VCMDeeplink = buildVCMDeeplink({
        scope,
        contentId,
        currentHost,
      });

      const loginDeeplink = `meli://login?go=${encodeURIComponent(
        VCMDeeplink,
      )}`;

      MobileWebKit.push({
        url: loginDeeplink,
        args: { replace: false },
        onError: async () => MobileWebKit.closeScreens('navigateToLogin error'),
      });
    },
    [currentHost, scope],
  );
  const navigateToExternalView = useCallback(
    (path: string) => {
      const externalhost = currentHost.replace('play', 'www');

      const externalFullPath = `${WEBVIEW_PREFIX}?url=${encodeURIComponent(
        `${externalhost}${path}`,
      )}`;

      MobileWebKit.push({
        url: externalFullPath,
        args: {
          replace: false,
        },
      });
    },
    [currentHost, scope],
  );

  return {
    navigateToVCM,
    navigateToRecommendation,
    navigateToLogin,
    reloadVCM,
    navigateToCFS,
    handleBack,
    navigateToExternalView,
    handleCloseAll,
  };
};
