import type { LoginMessageProps } from './LoginMessage.type';

import { useI18n } from 'nordic/i18n';

import { MessageElement } from '../../../../../components/MessageElement';

export const LoginMessage = ({ title }: LoginMessageProps) => {
  const { i18n } = useI18n();

  const messageFormat = {
    wrapper: 'span' as keyof JSX.IntrinsicElements | null,
    tags: {
      0: '<strong>',
      1: title || '',
      2: '</strong>',
    },
    className: 'text-not-found',
  };

  const message = i18n.jsx.gettext(
    'Inicia sesión con tu cuenta de Mercado Libre para ver {0}{1}{2} gratis',
    messageFormat,
  );

  return <MessageElement hierarchy="quiet" icon="default" labeljsx={message} />;
};
