import type { ContextValue } from '../../../../pages/hub/context';

import { useContext, useEffect } from 'react';

import { HubContext } from '../../../../pages/hub/context';

export const WEBVIEW_EVENTS = {
  VCM_ERROR: 'vcm_error',
  REMOVE_RESTRICTION_HUB: 'remove_restriction_hub',
};

type WebviewEvent = {
  fnRemoveRestriction?: () => void;
};

export const useWebviewEvents = ({ fnRemoveRestriction }: WebviewEvent) => {
  const { getSnackbarById, data, setErrorAction, errorAction } =
    useContext<ContextValue>(HubContext);

  const showSnackbarWhenErrorOcurred = () => {
    window?.MobileWebKit?.events.subscribe(
      WEBVIEW_EVENTS.VCM_ERROR,
      (event) => {
        // @ts-expect-error: its not correctly inferring the type
        if (event.detail?.args?.snackbarError) {
          // @ts-expect-error: its not correctly inferring the type
          setErrorAction(event.detail?.args?.snackbarError as string);
          getSnackbarById(
            // @ts-expect-error: its not correctly inferring the type
            event.detail?.args?.snackbarError as string,
          )?.showSnackbar();
        }
      },
    );
  };

  const removeRestrictionWhenIsClickedOnOtherView = () => {
    window?.MobileWebKit?.events.subscribe(
      WEBVIEW_EVENTS.REMOVE_RESTRICTION_HUB,
      (event) => {
        // @ts-expect-error: its not correctly inferring the type
        if (event.detail?.args?.reload) {
          fnRemoveRestriction?.();
        }
      },
    );
  };

  useEffect(() => {
    showSnackbarWhenErrorOcurred();
    removeRestrictionWhenIsClickedOnOtherView();
  }, [setErrorAction, getSnackbarById, fnRemoveRestriction, data, errorAction]);
};
