import type { FC, PropsWithChildren } from 'react';
import type {
  ContextState,
  ContextValue,
  DataProps,
  FilterProps,
  OverlayLoading,
  SnackBarConfig,
} from './HubState.types';

import {
  createContext,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';

import hubReducer, { HubActions } from '../reducers';

const initialState: ContextState = {
  nextPage: null,
  filter: null,
  previousFilter: null,
  data: undefined,
  snackbars: [],
  overlayLoading: {
    show: false,
    components: {},
  },
  isMuted: true,
};

export const HubContext = createContext(initialState as ContextValue);

export const HubProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(hubReducer, initialState);
  const [errorAction, setErrorAction] = useState<string>('');

  const updateFeed = useCallback(
    ({ data, nextPage }: DataProps) =>
      dispatch({
        type: HubActions.NEXT_PAGE_AND_DATA,
        payload: { data, nextPage },
      }),
    [state.nextPage],
  );

  const updateFilter = useCallback(
    ({ filter }: FilterProps) =>
      dispatch({
        type: HubActions.SET_FILTER,
        payload: { filter },
      }),
    [],
  );

  const setInitialData = useCallback(
    ({ data, nextPage }: DataProps) =>
      dispatch({
        type: HubActions.SET_INITIAL_DATA,
        payload: { data, nextPage },
      }),
    [],
  );

  const setInitialFilter = useCallback(
    ({ filter }: FilterProps) =>
      dispatch({
        type: HubActions.SET_INITIAL_FILTER,
        payload: { filter, nextPage: null },
      }),
    [],
  );

  const addNewSnackbar = useCallback(
    (
      id: string,
      showSnackbar: (config?: SnackBarConfig) => void,
      hideSnackbar: () => void,
    ) =>
      dispatch({
        type: HubActions.ADD_NEW_SNACKBAR,
        payload: {
          snackbar: { id, showSnackbar, hideSnackbar },
        },
      }),
    [],
  );

  const setShowOverlayLoading = (visible: boolean) => {
    dispatch({ type: HubActions.SHOW_OVERLAY_LOADING, payload: { visible } });
  };

  const setComponentsOverlayLoading = useCallback(
    (components: OverlayLoading['components']) => {
      dispatch({
        type: HubActions.SET_COMPONENTS_OVERLAY_LOADING,
        payload: {
          components,
        },
      });
    },
    [],
  );

  const getSnackbarById = useCallback(
    (id: string) => state.snackbars.find((snackbar) => id === snackbar.id),
    [state.snackbars],
  );

  const handleMutedChange = useCallback(() => {
    dispatch({
      type: HubActions.CHANGE_MUTED_VALUE,
    });
  }, []);

  const updateCarouselsComponents = useCallback(
    ({ data }: DataProps) =>
      dispatch({
        type: HubActions.UPDATE_CAROUSELS_COMPONENTS,
        payload: { data },
      }),
    [],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorActionValue = urlParams.get('error_action') || null;

    if (errorActionValue) {
      setErrorAction(errorActionValue.trim());

      urlParams.delete('error_action');

      const newUrl = `${window.location.pathname}${
        urlParams.toString() !== '' ? `?${urlParams.toString()}` : ''
      }`.trim();

      window.history.replaceState({}, '', newUrl);
    }
  }, [errorAction]);

  const memoizedContext = useMemo(
    () => ({
      ...state,
      updateFeed,
      updateFilter,
      setInitialFilter,
      setInitialData,
      addNewSnackbar,
      getSnackbarById,
      setShowOverlayLoading,
      setComponentsOverlayLoading,
      handleMutedChange,
      updateCarouselsComponents,
      setErrorAction,
      errorAction,
    }),
    [
      state,
      updateFeed,
      updateFilter,
      setInitialFilter,
      setInitialData,
      addNewSnackbar,
      getSnackbarById,
      setErrorAction,
      errorAction,
    ],
  );

  return (
    <HubContext.Provider value={memoizedContext}>
      {children}
    </HubContext.Provider>
  );
};
